import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    
    constructor(private authenticationService: AuthService) {}

    // This is an interceptor for all HTTP calls from the front-end.
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authenticationService.isAuthenticated() && req.url.indexOf('basicauth') === -1) {
            console.log('User is not authenticated. Will set Authorization header.');

            let authReq: any = null;

            if (req.url.includes('user/resetPasswordRequest') ||
                req.url.includes('user/verifyChallengequestions') ||
                req.url.includes('/state') ||
                req.url.includes('/user/admin') || 
				req.url.includes('/user/challengeQAndAVerification') ||
                req.url.includes('/user/resetAuthenticatedUserMFA')) {
                // Do not add 'Authorization' header and no credentials to pass
                authReq = req.clone({
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                    })
                });
            } else {
                authReq = req.clone({
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + sessionStorage.getItem('usercreds')
                    }),
                    withCredentials: true
                });
            }

            return next.handle(authReq);
        } else {

            let authReq = req.clone({
                withCredentials: true
            });

            return next.handle(authReq);
        }
    }
}
